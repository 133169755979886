<template >
  <v-row>
    <v-col>
      <template>
        <v-expansion-panels v-model="value">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h2 class="mb-4">Detalle del evento</h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content style="position: relative">
              <v-row v-if="eventSelected">
                <v-col cols="12" md="6">
                  <p>
                    <span class="font-weight-bold">Nombre</span>:
                    {{ eventSelected.event_name }}
                  </p>
                </v-col>
                <v-col cols="12" md="12">
                  <p class="title">
                    url
                    <a
                      :href="eventSelected.url_meet"
                      target="_blank"
                      :style="`color: ${eventSelected.color}`"
                      class="font-weight-bold text-decoration-none"
                    >
                      : {{ eventSelected.url_meet }}</a
                    >
                  </p>
                </v-col>

                <v-col cols="12" md="6">
                  <p><span class="font-weight-bold">Fecha</span>:</p>

                  <p class="subtitle-1">
                    {{ eventSelected.date_start | formatDateHours }}
                  </p>
                </v-col>
              </v-row>
              <v-row v-else class="py-4 justify-center">
                <Loading />
              </v-row>
              <v-btn
                fab
                @click="goBack"
                color="primary"
                depressed
                style="position: absolute; right: 10px; bottom: 10px"
              >
                <v-icon>mdi-keyboard-backspace</v-icon>
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { formatDateHours } from "../../filters/moment";
import Loading from "../../components/shared/Loading.vue";

export default {
  components: { Loading },
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Detalle del evento",
      };
    },
  },
  async created() {
    const resp = await this.getEventById(this.$route.params.id);
    if (!resp) {
      this.$router.replace({ name: "MyCalendary" });
      return;
    } else {
      this.eventSelected = resp;
    }
  },
  filters: {
    formatDateHours,
  },
  data() {
    return {
      eventSelected: null,
      value: 0,
    };
  },

  methods: {
    ...mapActions("calendar", ["getEventById", "setErrors"]),
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>